// Please make sure regularly that alpinejs version is the same as what is bundled with the current livewire version
import Alpine from 'alpinejs';
// import { Alpine } from '/vendor/livewire/livewire/dist/livewire.esm';

// USE the same plugin list for both this and the livewire.js file
import resize from '@alpinejs/resize';
import intersect from '@alpinejs/intersect';

import FontScale from './alpine/font-scale';
import FloatingUI from "./alpine/floating-ui";
import Auth from "./alpine/auth";
import User from "./alpine/user";

import input from './alpine/components/input';
import radioGroup from './alpine/components/radioGroup';
import select from './alpine/components/select';
import tableRow from './alpine/components/tableRow';

Alpine.plugin(resize);
Alpine.plugin(intersect);

Alpine.plugin(FontScale);
Alpine.plugin(FloatingUI);
Alpine.plugin(Auth);
Alpine.plugin(User);

Alpine.plugin(input);
Alpine.plugin(radioGroup);
Alpine.plugin(select);
Alpine.plugin(tableRow);

// We should start alpine after registering all plugins and data
window.Alpine = Alpine;
window.Alpine.start();
